/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Calligraffitti&display=swap');

.MuiDataGrid-columnHeaders {
  background-color: #eaeaeb !important;
}

.MuiDataGrid-virtualScroller {
  min-height: 50px !important;
  max-height: 50vh !important;
}

p {
  margin: 0;
  padding: 0;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Calligraffitti']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Calligraffitti']::before {
  font-family: 'Calligraffitti', cursive !important;
  content: 'Calligraffitti' !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='san']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='san']::before {
  font-family: sans-serif !important;
  content: 'Sans Serif' !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
  font-family:
    Georgia,
    Times New Roman,
    serif !important;
  content: 'Serif' !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
  font-family:
    Monaco,
    Courier New,
    monospace !important;
  content: 'Monospace' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Arial']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Arial']::before {
  font-family: Arial, Helvetica, sans-serif !important;
  content: 'Arial' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Calibiri']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Calibiri']::before {
  font-family: 'Open Sans', sans-serif !important;
  content: 'Calibiri' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='times']::before,
.ql-picker.ql-font .ql-picker-item[data-value='times']::before {
  font-family: 'Times New Roman', Times, serif !important;
  content: 'Times New Roman' !important;
  display: inline !important;
  /* font-size: x-small !important; */
}

/* Set content font-families */
.ql-font-times {
  font-family: 'Times New Roman', Times, serif !important;
}
.ql-font-Calibiri {
  font-family: 'Open Sans', sans-serif !important;
}
/* Set content font-families */
.ql-font-Arial {
  font-family: 'Arial' !important;
}

.ql-font-serif {
  font-family:
    Georgia,
    Times New Roman,
    serif !important;
}
.ql-font-monospace {
  font-family:
    Monaco,
    Courier New,
    monospace !important;
}

.ql-font-Calligraffitti {
  font-family: 'Calligraffitti', cursive !important;
}

.ql-font-san {
  font-family: sans-serif !important;
}

.ql-toolbar.ql-snow .ql-picker-label {
  overflow: hidden !important;
}
